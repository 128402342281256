import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Patenting",
  "description": "A guide to protect your product with patents.",
  "author": "Orkun Ozturk",
  "categories": ["certification"],
  "date": "2022-04-18T00:00:00.000Z",
  "featured": false,
  "tags": ["", "", "", "", ""],
  "relatedArticles": ["", "", "", ""]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Before describing what patents are, we would like to draw your attention to what they are NOT. In the realm of IoT, the speed of innovation is quite fast. These innovations aren’t made possible only due to patent ownership. So don’t be discouraged if your solution can’t be patented, there are other ways to protect your inventions.`}</p>
    <p>{`A patent is an intellectual property that gives you the right to exclude your competitors from using your invention. For utility patents, this gives you a 20 year protection period. During that time, you have the sole right to manufacture and sell your inventions. If you find out that a competitor is infringing on your patent claims, you can take legal action.`}</p>
    <p>{`Even if your company isn’t in the US, you are most likely to apply for a patent through USPTO, the US government office that’s responsible for patents and trademarks. There are two kinds of patents: `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/articles/Patenting/#utility-patents"
          }}>{`Utility Patent`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/articles/Patenting/#design-patents"
          }}>{`Design Patent`}</a></p>
      </li>
    </ul>
    <p>{`A utility patent is responsible for covering how your product `}<strong parentName="p">{`works`}</strong>{`, while a design patent protects the `}<strong parentName="p">{`looks`}</strong>{` of your product. You can apply for both a utility and design patent for the same product. `}</p>
    <p>{`Even if you’re not considering filing a patent application, you should look at other patents in your field in order to know more about your competitors. `}</p>
    <h2 {...{
      "id": "design-patents"
    }}>{`Design Patents`}</h2>
    <p>{`Design patents protect the non-functional aspects of your product. This is called ornamentation in legal jargon. Once issued, they are valid for 15years. The issuing of a design patent typically takes 1 or 2 years. While you may associate the bulk of IoT products with their functionality, as opposed to their aesthetics, don’t forget that these aesthetics play a significant role in consumer products. Wearables and home automation are two examples of IoT segments that fall into this category. Although you may not always seek a novel working mechanism for your product, you could make it look so that its design is patentable. When thinking about ornamentation, consider the configuration or the shape of the product and surface texture. Study product design principles, and look into the competition to see where you can make a difference. Alternatively, you can ask your manufacturing partner to learn more about molding`}{`[injection molding]`}{` and surface treatment`}{`[me/surface treatment]`}{` options. Since the finished product aesthetics will depend on how the product is manufactured, we would advise you to wait until the end of your pilot run to file a design patent application.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "581px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "59.07335907335908%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABcRAAAXEQHKJvM/AAADMUlEQVQoz1VTXUhbZxh+K5QKTcFC0yQnYk7WpdoSqUuONTml1thEi7q6uJZqY2Nw0IvNbrso/bGWSm9kKMYbYTdTvMgu4kXRKDJaNmwEwV24i01BdDf+EMGfhKpJk3O+Z3yHWNgLL3zfC9/D87zP8xFj7B1vAP/r/PwtgDhjrCabzdLAwMDJWo+HLl++RKGOIIVCQersDNHdO18XvOh+Rk+fPCZCvpLJpNaZTAaqqmozxpjWAAIAiFedz2eulKSaUCjo6uwM2R8+/MbY0uKn169fUe+rl0SMMWVxcVGJx+NKLBZT5ubmlFQqpaTT6RyALAfMZDJtY2NjJLvdDTqd7sNFmw0+rxceTw1u1dcn77e1/jI7++7U+/e/E62uriISiWBychLT09NYW1vT2O3v77PDw0M1L+AOZyhaLP8WFhaizudLVVy5kvP5fKzF34z29ja87Om+/fzZE6J4PI6dnR1MTExgaWlJk5zL5TTFe3t76tHRET836E6fvlFcXAyT0YimxsbFcrs9U+vxoKMj8LH9QSt+/OHRT/6vmolWVlY0kPn5eSwvL2t00uk0stksSyaTKt8rgGYiMltFkV0qK0N74P6312Q5IbtlBIMPcoFAK75/9N0fkuQk2t7e1gCnpqYwMzNz7BE3g21tbanr6+v86iciXbnd/vFGdTWX579VX/ebaLHgi4qKzNXKq/iyqfEfzTXOiu9xeHgY4XAY4+PjGtOFhQUWDofVRCLBAe/yHVZWSksuVxVu3qz91VVV5SgrLU0Wm83gwKJFbLLZbESbm5sYHR3F0NAQ+vv70dvbi66uLni9XhaJRNR8pNr6+vrI6XC8KbfbIcvuP7u7n+rLSksTHOzzCxd+NhoMZDQYCohnbnd3FxsbG4hGoxgcHERPTw9GRkZ4ADVAHptoNEqy7G5wOhyovn4dktPxt9FggFkQ/rKK4hmxpIQ+s1pPfAr2caVSKRwcHGhr5ID5YN87DrbsdrdKkjQrmEzjZ4uKXpzX6/WcnSAIBaLFogVb+w2caf6xVoqicF/UvEH3FEWhqVjspOR0ktvlIqso0tmiItKfO0dmQTghmExkKSmh/wBOYEeMH2jpTgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/7faacb37cef3cd287e737ab5f986b101/652f5/Patenting-04.webp 259w", "/static/7faacb37cef3cd287e737ab5f986b101/c29d2/Patenting-04.webp 518w", "/static/7faacb37cef3cd287e737ab5f986b101/39daa/Patenting-04.webp 581w"],
            "sizes": "(max-width: 581px) 100vw, 581px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/7faacb37cef3cd287e737ab5f986b101/a2ead/Patenting-04.png 259w", "/static/7faacb37cef3cd287e737ab5f986b101/6b9fd/Patenting-04.png 518w", "/static/7faacb37cef3cd287e737ab5f986b101/92d15/Patenting-04.png 581w"],
            "sizes": "(max-width: 581px) 100vw, 581px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/7faacb37cef3cd287e737ab5f986b101/92d15/Patenting-04.png",
            "alt": "2018 Design Patent of Fitbit and Fitbit Versa 2",
            "title": "2018 Design Patent of Fitbit and Fitbit Versa 2",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`The line drawing is from a 2018 design patent that belongs to Fitbit, pictured next to Fitbit Versa 2, released the next year.`}</p>
    <h2 {...{
      "id": "utility-patents"
    }}>{`Utility Patents`}</h2>
    <p>{`Utility patents protect the functional aspects of your inventions. This includes any process, machinery, material, or manufactured article that is unique to the invention.  In the realm of IoT devices, a utility patent will most likely be related to the processes or manufactured articles. Even if you’re not inventing a new chip, is the way you designed your product unique? For example, a `}<a parentName="p" {...{
        "href": "https://patents.google.com/patent/US20160332060A1/en?q=skateboard+accelerometer&oq=skateboard+accelerometer"
      }}>{`device`}</a>{` attached to a skateboard that collects data from an accelerometer to count flips could be granted a utility patent. The novelty of the application was enough to earn a patent. You can start working on your utility patent once you have confirmed your proof of concept. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "83.39768339768338%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABaklEQVQ4y5WT6W7CMBCEef9HBCEUFESs+L7W13orxYiilhR1fjr5tOMZ74E+qfe+9+nwET4ej4wxpRQi/gX33mutOedSSs651kpE0zSdTidrbYxxF26tAUBKqW8aJ6UUIuKcX6/XeZ6tta+3eMCImHNurRljxnCt9ZhmrVVKMcY45z+cH55jU0oxxhDCuq7WWmOM1toYI6X8EFgpBREB4H6/M8aWZVFK3W43znmtdS/wB5w3ee8BQAhxuVzO5/O6riEEACCiWisAvLeNiNba1hoROecAwBiTUhJCAMDIEhF/WPhOGzaVUuomrbVSKqU0Cntib9J+FoOIg2+tee9zzq9WRy7vH0lKaYQ3LPTex8nAiMhauwuPwpxznPNSilIqhJBzBgC/SQgRQth921LKGOMoTGs9/l6WxXu/rus0TX8txpg8z7MQwjnnvSeiGGPZJKV8jeDNVoUQYoxaaynliPp/K9l7/93qb/gLp3TkZHriDMwAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/63ab29e8a813234886ce38907099aaa4/652f5/Patenting-03.webp 259w", "/static/63ab29e8a813234886ce38907099aaa4/c29d2/Patenting-03.webp 518w", "/static/63ab29e8a813234886ce38907099aaa4/77d2c/Patenting-03.webp 1035w", "/static/63ab29e8a813234886ce38907099aaa4/ba7d2/Patenting-03.webp 1553w", "/static/63ab29e8a813234886ce38907099aaa4/fad48/Patenting-03.webp 1600w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/63ab29e8a813234886ce38907099aaa4/a2ead/Patenting-03.png 259w", "/static/63ab29e8a813234886ce38907099aaa4/6b9fd/Patenting-03.png 518w", "/static/63ab29e8a813234886ce38907099aaa4/e3189/Patenting-03.png 1035w", "/static/63ab29e8a813234886ce38907099aaa4/44d59/Patenting-03.png 1553w", "/static/63ab29e8a813234886ce38907099aaa4/29007/Patenting-03.png 1600w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/63ab29e8a813234886ce38907099aaa4/e3189/Patenting-03.png",
            "alt": "Utility patent drawing of skateboard tracking device",
            "title": "Utility patent drawing of skateboard tracking device",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`These utility patent drawings illustrate the working mechanism of the `}<a parentName="p" {...{
        "href": "https://patents.google.com/patent/US20160332060A1/en?q=skateboard+accelerometer&oq=skateboard+accelerometer"
      }}>{`skateboard tracking device`}</a>{`.`}</p>
    <p>{`Utility patents allow you to block competition for 20 years. Compared to design patents, utility patents cost more to prepare, file, and maintain. They also take around 3-5 years to obtain, a timeframe considerably longer than of design patents.  `}</p>
    <h2 {...{
      "id": "filing-a-patent-application"
    }}>{`Filing a Patent Application`}</h2>
    <p>{`In order to obtain a design or utility patent, you must first submit a non-provisional patent application. This long and tedious process requires you to know the working mechanism or the aesthetics of your product very well. If you’re just starting out, you may:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Not know the details of your invention very well yet, `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Not have a budget for a full-fledged patent application, `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`and May not want to commit to a 3-5 year undertaking without testing your market. `}</p>
      </li>
    </ul>
    <p>{`But there’s a way to protect your invention, without making it such a massive undertaking.
This option is called a `}<em parentName="p">{`provisional patent application`}</em>{`. Whenever you see “Patent pending”, you are actually looking at a product with a provisional patent application. `}</p>
    <h3 {...{
      "id": "provisional-utility-patent"
    }}>{`Provisional Utility Patent`}</h3>
    <p>{`Unlike a non-provisional patent application(i.e. For the actual utility patent), provisional patent applications are not reviewed by USPTO. They allow you to prove that you’ve been working on this idea at least since the filing date of your provisional application. `}</p>
    <p>{`You should note that you can only file provisional patent applications for utility patents, not design patent applications.`}</p>
    <p>{`If you are sharing your idea with third parties and you don’t want it to be stolen, filing a provisional patent application will stop your competitors from claiming any prior art. Prior art simply means any claim that can be used against your patent application.`}</p>
    <p>{`If you consecutively file a non-provisional application within 1 year, and your latter application can prove that you’ve made progress on your initial idea, your patent will have a validity period of 20 years starting from the initial filing date of your provisional application.`}</p>
    <p>{`Provisional patent applications can be written without the counsel of a legal professional and they only cost a few hundred dollars or less to file. Although there’s no strict format you must follow, your application should include:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Specification`}</strong>{`: Describe the invention and how it’s used. Use clear and concise language as if you are talking to someone who is educated in the field of your invention. `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Title of the invention`}</strong>{`: This is not the brand name of your product. Describe what your product is with a few words. `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Cross-Reference to Related Applications`}</strong>{`: Every patent pays tribute to prior art. Don’t think of this as damaging to your claims. If a similar technology was used in another field, it doesn’t necessarily stop you from implementing it elsewhere. `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Background of the invention`}</strong>{`: Provide some context of the use case. List problems currently existing in this area of technology. `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Brief Summary`}</strong>{`: Summarize the advantages of your invention. `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Drawings`}</strong>{`: Show all features of the invention as made clear in the claims. `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Description of drawings`}</strong>{`: Briefly describe the figures attached with your application. Use numbers in your drawings so that you can discuss them here. `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Detailed description of the invention`}</strong>{`: This is the most difficult part of your application. You will need to explain exactly how your invention works. Don’t assume that the reader knows everything. Imagine you are literally very small and you’re walking around your invention. Explain every part, every movement, every placement, and every action taking place in your invention. Explain why this invention makes improvements to the current state of technology or art, and make sure you distinguish it from related applications. `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Claim(s)`}</strong>{`: Patents are as strong as their claims. Make your claims too broad and you risk refusal, make them too weak and they don’t amount to any protection. `}</p>
      </li>
    </ol>
    <p>{`For a full list of requirements, take a look at this checklist: `}<a parentName="p" {...{
        "href": "https://www.uspto.gov/sites/default/files/inventors/Checklist_for_Filing_a_Nonprovisional_Utility.pdf"
      }}>{`https://www.uspto.gov/sites/default/files/inventors/Checklist_for_Filing_a_Nonprovisional_Utility.pdf`}</a></p>
    <h3 {...{
      "id": "non-provisional-utility-patent"
    }}>{`Non-provisional Utility Patent`}</h3>
    <p>{`Once you have established your idea during the 12 months upon your provisional patent application, it is now time to submit a non-provisional patent application. Unlike the former, these are full-fledged utility patent applications that require a patent attorney to file. Once you submit your application, you will have to wait around 18 months before an examiner from the USPTO will look at your claims, prior art and the legitimacy of your application. Depending on their verdict, you will need to respond to them by either accepting their verdict, or rejecting it. Expect to file at least one rejection before you can get your point across. You should work with your attorney to strengthen your claims or word them better. Otherwise, the examiner might think that your claims are too broad, or are written in prior art by someone else. An examiner may also reply to you with a Restriction Requirement. This is basically the examiner only accepting some of your claims and rejecting others. You’re also free to accept or reject this verdict. But in all scenarios, the granting of your patent depends on the government decision. Keep in mind, the benefits of having a utility patent are offset by the difficulty of obtaining one. `}</p>
    <p>{`For more information regardings the non-provisional utility patent, visit `}<a parentName="p" {...{
        "href": "https://www.uspto.gov/patents/basics/types-patent-applications/nonprovisional-utility-patent"
      }}>{`https://www.uspto.gov/patents/basics/types-patent-applications/nonprovisional-utility-patent`}</a></p>
    <h2 {...{
      "id": "costs-involved-with-patents"
    }}>{`Costs Involved with Patents`}</h2>
    <p>{`As of Jan 2, 2022, the USPTO charges the fees below for new patent applications:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Regular Fee`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Small Entity*`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Micro Entity**`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Design Patent Filing Fee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`220$`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`110$`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`55$`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Utility Patent Filing Fee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`320$`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`160$`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`80$`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Provisional Application Filing Fee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`300$`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`150$`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`75$`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`*A `}<a parentName="p" {...{
        "href": "https://mpep.uspto.gov/RDMS/MPEP/e8r9#/e8r9/d0e30961.html"
      }}>{`small entity`}</a>{` refers to individuals, small businesses, and non-profit organizations anywhere in the world. `}</p>
    <p>{`** `}<a parentName="p" {...{
        "href": "https://www.uspto.gov/patents/laws/micro-entity-status"
      }}>{`Micro entities`}</a>{` should qualify as small entities, and prove that they haven’t been named in more than 4 patent applications. They should also make less than 3 times the US median household income (i.e. less than around 240 thousand dollars in 2021). `}</p>
    <p>{`Working with a professional patent attorney will set you back around 5 to 10 thousand US Dollars for your non-provisional application. Expect to spend a similar amount if you decide to reject the examiner’s verdict. `}</p>
    <h2 {...{
      "id": "faqs"
    }}>{`FAQs`}</h2>
    <p><strong parentName="p">{`Can I submit a patent application on my own?`}</strong>{` `}</p>
    <p>{`Yes and no. You can submit a provisional patent application without legal assistance. That can also allow you to better understand and explain how your product works. However, you shouldn’t file non-provisional patent applications on your own.`}</p>
    <p><strong parentName="p">{`How can I protect my product if it's unpatentable?`}</strong>{` `}</p>
    <p>{`Look for alternative ways to keep copycats at bay. `}<a parentName="p" {...{
        "href": "https://www.uspto.gov/trademarks"
      }}>{`Trademark`}</a>{` your brand along with similar sounding names. Or simply offer a great brand experience so that people stay with you. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      